<script>
import faq from '~/components/layout/faq'

export default {
  name: 'MoottoripyoralainaFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Miten haen moottoripyörään rahoitusta?',
          content: 'Täytä lainahakemus huolellisesti sivuillamme ja vertaile lainatarjoukset.'
        },
        {
          title: 'Kuka voi hakea moottoripyörälle rahoitusta?',
          content:
            'Rahoitusta moottoripyörään voi hakea täysi-ikäinen, jolla on säännölliset tulot ja luottotiedot kunnossa.'
        },
        {
          title: 'Kuinka paljon voin hakea lainaa moottoripyörään?',
          content:
            'Rahalaitoksen kautta voit hakea lainaa moottoripyörään 500 eurosta jopa 60 000 euroon asti.'
        },
        {
          title: 'Sitoudunko ottamaan lainan?',
          content:
            `Lainahakemuksen täyttäminen ja lainatarjousten vertailu ei sido tai hakijaa mihinkään. Jos päätät
            hyväksyä jonkun sinulle tehdyistä lainatarjouksista, tapahtuu sopimuksen allekirjoitus erikseen.`
        },
        {
          title: 'Tarvitsenko vakuuksia tai takaajia?',
          content:
            'Et. Säännölliset palkka- tai eläketulot ja maksuhäiriövapaat luottotiedot toimivat vakuutenasi.'
        },
        {
          title: 'Mistä löydän edullisen moottoripyörälainan?',
          content:
            `Löydät edullisen lainan Rahalaitoksen sivuilta kilpailuttamalla yhdellä hakemuksella yli 20 pankkia ja
            luotontarjoajaa. Saamistasi tarjouksistasi saat itse valita sopivimman.`
        },
        {
          title: 'Kuinka nopeasti saan rahat käyttöön?',
          content:
            'Rahat siirretään tilillesi nopeasti, usein saman päivän aikana lainasopimuksen allekirjoitettuasi.'
        },
        {
          title: 'Mikä on pisin laina-aika?',
          content:
            `Pisin laina-aika on 180 kuukautta. Yleisimmin moottoripyörälainan takaisinmaksuajaksi otetaan
            5 - 7 vuotta.`
        },
        {
          title: 'Kuinka kallis on moottoripyörälaina?',
          content:
            `Todellinen vuosikorko on aina henkilökohtainen. Oman korkotasosi tarkistat sitoutumatta täyttämällä helpon
            lainahakemuksen Rahalaitos.fi sivustolta.`
        }
      ]
    }
  }
}
</script>
