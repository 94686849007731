<template>
  <div class="content">
    <breadcrumb
      :path="{'Yhdistä lainat': '/yhdista-lainat', 'Yhdistä lainat ja säästä': ''}"
    />
    <img style="width: 100%;" :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
    <h1>Teija yhdisti lainansa ja säästää nyt lainakuluissa satoja euroja</h1>
    <p style="font-weight: 900;">
      Lainan maksuaika lyheni ja kuukausierä pieneni huomattavasti.
    </p>
    <p>
      Lapsiperheen talous voi välillä aiheuttaa vanhemmille päänvaivaa, kun samaan ajankohtaan osuu useita isompia hankintoja. Usein vaikkapa uusien huonekalujen, television tai tietokoneen hankinnassa tarvitaan luottokorttia tai hankinta rahoitetaan kulutusluotolla.
    </p>
    <p>
      Kahden lapsen äiti <b>Teija</b>, 43 asuu omakotitalossa, jonka asuntolaina on jo lainanmaksun osalta paremmalla puolella. Hän työskentelee sairaanhoitajana ja hänen miehensä rakennusalalla. Teijalla on kaksi luottokorttia, joita oli käytetty erilaisiin hankintoihin ja luottorajat 2000 euroa ja 1000 euroa olivat tulleet täyteen. Lisäksi uuden sohvan ostoon oli otettu 1000 euron kulutusluotto. Näitä kolmea lainaa hän lyhensi joka kuukausi yhteensä 200 eurolla.
    </p>
    <p>
      Lainojen korot ja niiden sääntely on ollut viime aikoina paljon esillä julkisessa keskustelussa. Se sai myös Teijan tarkastelemaan omien luottojensa korkoja.
    </p>
    <p>
      Teija huomasi, että luottokorttien ja kulutusluoton korot olivat selväsi yli syyskuussa voimaan tulevan 20 prosentin korkokaton. Hän näki mainoksen <nuxt-link to="/yhdista-lainat">lainojen yhdistämisestä</nuxt-link>, ja päätti kokeilla, voisiko säästää sen avulla kuukausittaisista lainakuluistaan.
    </p>
    <h2>
      Reilu säästö kuukausittaisista lainakuluista
    </h2>
    <p>
      Teija kilpailutti lainansa <nuxt-link to="/">Rahalaitoksella</nuxt-link>, joka on suomalainen lainanvälittäjä, joka kilpailuttaa lainat yli 20 pankilla. Hän haki 4 000 euron yhdistelylainaa, jolla maksaisi pois pienemmät luottonsa.
    </p>
    <p>
      Parissa minuutissa Teija sai tarjouksia Oma Rahalaitos -palveluun, jonka tunnukset hän oli saanut hakemuksen lähettämisen yhteydessä. Lainatarjouksissa oli merkittäviä eroja, mutta palvelun avulla oli helppo verrata ja valita niistä paras. Teija päätyi yhdistelylainaan, jonka vuosikorko on 9,1 prosenttia, laina-aika kolme vuotta ja kuukausierä 130 euroa.
    </p>
    <p>
      Teija laski, että lainat tulee maksettu jopa nopeammin kuin aiemmalla maksutahdilla ja hän maksaa silti 70 euroa vähemmän joka kuukausi. Vuodessa hänelle jää käteen 840 euroa enemmän kuin ennen, ja kolmen vuoden laina-aikana peräti 2 520 euroa.
    </p>
    <p>
      - Lapsiperheen arjessa tuo säästetty rahamäärä vaikuttaa elämiseen mukavasti ja kuinka pienellä vaivalla homma hoitui, Teija kertoo.
    </p>
    <p>
      Rahalaitoksen kautta voi hakea lainaa 500-60 000 euroa. Riittää, että täytät hakemuksen, jonka jälkeen pääset kirjautumaan Oma Rahalaitos -palveluun. Sinne saat kootusti lainatarjoukset eri pankeilta. Jos hyväksyt jonkun tarjouksista, saat rahat tilillesi yleensä parissa päivässä, joskus jopa saman päivän aikana.
    </p>
    <span class="miniform-header"> Lainan kilpailutus 60 000 € asti </span>
    <mini-form />
    <share text="Haaveiletko urheilureissusta? Verkon avulla rakennat oman unelmiesi matkan" />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'TeijaYhdistiLainansa',
  components: {
    miniForm: () => import( '~/components/subpage/miniform' ),
    share: () => import( '~/components/subpage/share' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Yhdistä lainasi ja säästää nyt lainakuluissa satoja euroja',
      meta: [
        { hid: 'description', name: 'description', content: 'Onko sinulla useita lainoja? Lue, miten lainan maksuaikaa saa lyhyemmäksi ja kuukausierää pienemmäksi.' }
      ]
    }
  }
}
</script>
