<template>
  <div>
    <main-hero
      title="Hae lainaa"
      subtitle="Yhdellä hakemuksella useita lainatarjouksia."
      side-title="Löydä sopivin laina!"
      :side-boxes="sideBoxes"
      survey-title="Lainanhakijat ovat arvioineet"
      :breadcrumb="{'Lainaa': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Kokeile maksutta ja valitse paras laina.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>
            Täytä ilmainen lainahakemus – voit saada lukuisia lainatarjouksia 500-60 000 € lainoista
          </h2>
          <p>
            Elämässä tulee joskus vastaan tilanteita, joissa lainarahan tarve iskee äkisti. Esimerkiksi auton hajoaminen tai yllättävä lääkärikäynti saattavat aiheuttaa sen, että käyttöön tarvitaan lainaa nopeasti. Kun äkillinen lainantarve tulee, lainaa kannattaa pääsääntöisesti hakea verkon kautta, sillä perinteinen pankkiasiointi ei välttämättä onnistu aivan hetkessä.
          </p>
          <p>
            Rahalaitos on televisiostakin tuttu suomalainen lainanvälittäjä, joka toimii yhteistyössä lukuisien luotonmyöntäjien ja rahoituslaitosten kanssa. Täyttämällä yhden hakulomakkeen saat lainatarjoukset useilta lainanmyöntäjiltä – lainan hakeminen ja tarjousten vertailu ovat aina täysin maksutonta, eikä se sido sinua mihinkään.
          </p>
          <p>
            Voit ilman riskiä katsastaa saamasi tarjoukset nopeista lainoista ja päättää sen jälkeen, hyväksytkö jonkun niistä. Nopeimmillaan yhteistyökumppanimme maksavat lainan tilille heti, eli saat rahat käyttöösi vielä saman päivän aikana hakemuksen jättämisestä.
          </p>
          <p>
            Katsotaan seuraavaksi, kuinka lainan hakeminen Rahalaitoksen kautta tapahtuu.
          </p>
          <h3>
            Näin voit saada lainaa heti
          </h3>
          <p>
            Rahalaitoksen kautta voit hakea lainoja, jotka ovat suuruudeltaan 500-60 000 €. Tarkan summan määrittelet itse 100 euron tarkkuudella. Myös laina-ajan voit valita vapaasti 1-15 vuoden väliltä.
          </p>
          <p>
            Kun hakemus on täytetty, saat heti päätöksen siitä, voidaanko sinulle myöntää lainaa. Yhteistyökumppaneillamme on muutamia ehtoja, jotka sinun on täytettävä, jotta voit saada lainaa kauttamme.
          </p>
          <p>
            Alla näet yleiset kriteerit lainanhakijoille:
          </p>
          <ul>
            <li>Lainoja myönnetään vain 20-vuotiaille ja sitä vanhemmille henkilöille.</li>
            <li>Lainanhakijan kuukausitulojen on ylitettävä 600 €.</li>
            <li>Hakijalta vaaditaan säntillistä taustaa raha-asioiden hoidosta – julkisista maksuhäiriöistä ei saa löytyä merkintöjä.</li>
          </ul>
          <p>
            Lainan kilpailuttaminen onnistuu mikäli täytät mainitut kriteerit. Jos lainahakemuksesi hyväksytään, alat saada tarjouksia lainoista. Usein ensimmäiset lainatarjoukset tulevat heti parissa minuutissa, ja voit vertailla niitä keskenään Oma Rahalaitos -palvelussa.
          </p>
          <p>
            Hyväksyessäsi jonkin lainatarjouksista, saat nopeimmillaan lainan tilille heti. Kannattaa kuitenkin varmistaa, että sinä ja lainanmyöntäjä käytätte samaa pankkia. Useimmilla luotonmyöntäjillä yhteydet pelaavat nopeasti kaikkiin yleisimpiin pankkeihin, mutta tarkan tiedon saat aina luotonmyöntäjien verkkosivuilta.
          </p>
          <p>
            Rahalaitos toimii prosessissa ainoastaan lainan välittäjänä, joten emme voi vaikuttaa lainantarjoajien tarkkoihin maksuaikatauluihin – joissain tapauksissa lainan saaminen voi venyä seuraavalle tai sitä seuraavalle päivälle.
          </p>
          <p>
            Lainan haku kauttamme onnistuu viikon jokaisena päivänä kellon ympäri.
          </p>
          <h3>
            Et tarvitse vakuuksia tai takaajia saadaksesi lainaa
          </h3>
          <p>
            Lainalle asetettava vakuus tarkoittaa sitä, että joutuisit asettamaan omaisuuttasi lainan takaisinmaksun pantiksi. Rahalaitoksen yhteistyökumppanit kuitenkin tekevät arvion lainanhakijan maksukyvystä jo etukäteen, joten et tarvitse minkäänlaista vakuutta, jotta voisit hakea lainaa palvelumme avulla.
          </p>
          <p>
            Myöskään ulkopuoliselle henkilötakaajalle ei ole tarvetta – tämäkin nopeuttaa osaltaan lainanhakua ja samalla madaltaa kynnystä hakea lainaa. Kun laina on saatava tilille heti, sinulla ei välttämättä ole aikaa etsiä perhe- tai ystäväpiiristä takaajaa lainallesi.
          </p>
          <h3>
            Millaisia korkokustannuksia haettavat lainat sisältävät?
          </h3>
          <p>
            Saamiesi lainatarjousten korkokuluissa voi käytännössä olla hyvinkin suuria eroja. Vaikka tarvitsisit lainan heti, muista silti ensin vertailla tarkkaan eri vaihtoehtojen kulurakenteita.
          </p>
          <p>
            Tarkat kustannukset riippuvat muun muassa:
          </p>
          <ul>
            <li> haettavasta lainasummasta,</li>
            <li>laina-ajasta,</li>
            <li>lainanhakijasta sekä</li>
            <li>luotonmyöntäjästä.</li>
          </ul>
          <p>
            Voit saada paremmat lainaehdot, jos haet lainaa yhdessä toisen hakijan kanssa. Mikäli lainaraha tulee yhteiseen tarpeeseen esimerkiksi puolison kanssa, laina kannattaa hakea yhdessä. Yhteistyökumppanimme pystyvät takaamaan edullisemmat lainaehdot, kun lainasta on huolehtimassa kaksi henkilöä.
          </p>
          <p>
            Alla näet muutamia esimerkkejä erisuuruisten lainojen kustannuksista eripituisilla laina-ajoilla.
          </p>
          <ul>
            <li>Lainaa 2000 euroa vuoden mittaisella maksuajalla: lainan kuukausierä alkaen 174€/kk ja takaisin maksettava summa 2091€.</li>
            <li><nuxt-link to="/lainaa-5000-euroa">Lainaa 5000 euroa</nuxt-link> kolmen vuoden mittaisella maksuajalla: lainan kuukausierä alkaen 157€/kk ja takaisin maksettava summa 5665€.</li>
            <li><nuxt-link to="/lainaa-10000-euroa">Lainaa 10 000 euroa</nuxt-link> viiden vuoden mittaisella maksuajalla: lainan kuukausierä alkaen 204€/kk ja takaisin maksettava summa 12 252€.</li>
          </ul>
          <p>
            Huomaa että kyseessä on esimerkki lainalaskelma ja jokainen tarjous on yksilöllinen. Eri lainanmyöntäjien korot ja muut lainakustannukset näet kätevästi Oma Rahalaitos -palvelusta, josta voit vertailla kaikki saamasi tarjoukset.
          </p>
          <p>
            Juuri sinulle tarjottavat lainaehdot saat siis selville täyttämällä lainahakemuksen. Se on aina ilmaista, eikä lainan kilpailuttaminen sido sinua ottamaan lainaa. Jos saamiesi lainatarjousten joukosta ei löydy mieluisaa vaihtoehtoa, voit jättää lainan kokonaan ottamatta, eikä tästä koidu sinulle mitään kustannuksia.
          </p>
          <p>
            Hae siis lainaa nyt – ensimmäiset tarjoukset saat hetken kuluttua hakemuksen täyttämisestä.
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <faq />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'LainaaAlasivu',
  components: {
    LazyHydrate,
    banks: () => import('~/components/layout/banks'),
    faq: () => import('~/components/layout/pages/lainaaFaq'),
    loanSteps: () => import('~/components/layout/loanSteps'),
    mainHero: () => import('~/components/layout/mainHero')
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import('~/assets/img/icons/banks.svg?inline'),
          title: 'Tavoitat jopa',
          subtitle: '25 pankkia'
        },
        {
          icon: () => import('~/assets/img/icons/time.svg?inline'),
          title: 'Vaivatonta ja',
          subtitle: 'nopeaa'
        },
        {
          icon: () => import('~/assets/img/icons/money.svg?inline'),
          title: 'Lainaa 500 -',
          subtitle: '60 000 €'
        }
      ]
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Kilpailuta lainat',
        subtitle: 'Säästät selvää rahaa',
        link: '/kilpailuta-lainat'
      },
      {
        title: 'Lainaa 40 000 euroa',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-40000-euroa'
      },
      {
        title: 'Kilpailuta pohjoismaiset pankit',
        subtitle: 'Vertaile ja katso mistä saat edullisimman lainan',
        link: '/talous/suomalainen-ruotsalainen-vai-norjalainen-pankki',
        image: '/cms/uploads/large_photo_1538332576228_eb5b4c4de6f5_3bdf11ac3a.jpg'
      }
    ] )
  },
  head () {
    return {
      title: 'Hae lainaa - laina nopeasti ja helposti | Rahalaitos',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Rahalaitos kilpailuttaa jopa 25 pankkia puolestasi ilmaiseksi!
            ✔️Täysin suomalainen
            ✔️Lainapäätös heti
            ✔️Lainaa jopa 60 000 €
            ✔️Yksi hakemus - useita lainatarjouksia`
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/lainaa' }
      ]
    }
  }
}
</script>
