<template>
  <div class="content">
    <breadcrumb
      :path="{'Talous': '/talous', 'Kulutusluottojen säännöt uusiksi': ''}"
    />
    <img style="width: 100%;" :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
    <h1>Kulutusluottojen säännöt menivät uusiksi – Näin saat kaiken irti lakimuutoksesta</h1>
    <p>
      Suomessa tuli syyskuun alussa voimaan vakuudettomia kulutusluottoja rajoittava laki, jonka mukaan lainojen nimelliskorko saa olla korkeintaan 20 prosenttia ja lainan hoidosta aiheutuvat kulut korkeintaan 150 euroa vuodessa.
    </p>
    <p>
      Lainan hakijalle tämä on tietysti hyvä uutinen, sillä luotoista ei voida enää periä kohtuuttoman suuria korkoja ja kuluja.
    </p>
    <p>
      Siksi jokaisen, jolla on tällä hetkellä kulutusluottoja, tai joka suunnittelee sellaisen hakemista, kannattaa huomioida muutama asia.
    </p>
    <h2>1. Vanhat lainat eivät päivity</h2>
    <p>
      Vanhat, ennen lakimuutosta otetut lainat eivät muutu automaattisesti, vaan ne täytyy itse päivittää uuden korkolain mukaisiksi.
    </p>
    <p>
      Samalla on totta kai hyvä kilpailuttaa laina ja katsoa, millaisen koron siihen voisi parhaimmillaan saada. Jos kilpailuttaminen tuntuu vaikealta ja aikaa vievältä, kannattaa kääntyä Suomen johtavan lainanvälittäjän Rahalaitoksen puoleen.
    </p>
    <p>
      Kun täytät <nuxt-link to="/">hakemuksen Rahalaitoksen sivuilla</nuxt-link>, se kilpailutetaan jopa 25 yhteistyöpankilla. Saamasi lainatarjoukset tulevat Oma Rahalaitos -palveluun, johon pääset helposti kirjautumalla vertailemaan tarjouksia. Palvelussamme voit itse päättää, hyväksytkö jonkun tarjouksista.
    </p>
    <h2>
      2. Laske aina kokonaiskorko
    </h2>
    <p>
      Vaikka korkokatto rajoittaa lainasta perittäviä kuluja, kannattaa silti olla tarkkana lainan ehtojen kanssa. Etenkin pienten lainojen kohdalla korko ja kulut voivat edelleen nousta suhteessa kalliiksi, varsinkin jos niistä peritään maksimin mukaiset 150 euron vuosikulut.
    </p>
    <p>
      20 prosentin korkokatto koskee lainan nimelliskorkoa. <nuxt-link to="/">Rahalaitoksen kilpailutuspalvelussa</nuxt-link> lasketaan kuitenkin myös lainan todellinen vuosikorko, joten lisäkulut eivät pääse yllättämään.
    </p>
    <h2>
      3. Käytä muutos hyväksesi
    </h2>
    <p>
      Lakimuutos on pakottanut useat lainantarjoajat tarkastamaan korkojaan alaspäin. Jos sinulla siis on ennestään useampia kulutusluottoja, osamaksusopimuksia ja luottokorttivelkoja, on nyt loistava tilaisuus kilpailuttaa ne uudelleen ja yhdistää yhdeksi isommaksi lainaksi.
    </p>
    <p>
      Rahalaitoksen kautta <nuxt-link to="/yhdista-lainat">lainojen yhdistäminen</nuxt-link> on mahdollisimman helppoa ja vaivatonta. Täyttämällä yhden hakemuksen Rahalaitoksen sivuilla, voit hakea lainojen yhdistämistä 60 000 euroon asti.
    </p>
    <p>
      Täytä nyt lainahakemus muutamassa minuutissa ja näet millaisen koron sinä voisit saada lainaasi.
    </p>
    <span class="miniform-header"> Lainan kilpailutus 60 000 € asti </span>
    <mini-form go-to-url="/autolaina" />
    <share text="Kulutusluottojen säännöt muuttuivat | Huomioi nämä asiat" />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'AutonHankinta',
  components: {
    miniForm: () => import( '~/components/subpage/miniform' ),
    share: () => import( '~/components/subpage/share' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Kulutusluottojen säännöt muuttuivat | Huomioi nämä asiat',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Kulutusluottoja sääntelevä laki muuttui. Nämä asiat kannattaa huomioida kun tarvitset lainaa tai haluat kilpailuttaa nykyiset lainasi'
        }
      ]
    }
  }
}
</script>
