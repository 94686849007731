<template>
  <div class="top-points">
    <div class="top-points-section">
      <div class="top-points-section-content">
        <h2 class="is-size-3 has-text-weight-bold has-text-white">
          {{ title }}
        </h2>
      </div>
    </div>
    <div v-for="(box, key) in boxes" :key="key" class="top-points-section">
      <div class="top-points-section-content">
        <div class="top-points-section-block top-points-icon">
          <component :is="box.icon" />
        </div>
        <div class="top-points-section-block">
          <h3>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="subtitle is-5 has-text-weight-bold" v-html="box.title" />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="title is-4 has-text-weight-bold" v-html="box.subtitle" />
          </h3>
        </div>
      </div>
    </div>
    <lazy-hydrate
      v-if="! ['/yrityslainat', '/asuntolainat'].includes( this.$route.path )"
      ssr-only
    >
      <surveymonkey :title="surveyTitle" />
    </lazy-hydrate>
    <p class="has-text-white is-size-7 m-2">
      *Asiakasarviot perustuvat palvelua käyttäneiltä
      asiakkailta sähköpostitse pyydettyihin arvioihin.
    </p>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    surveymonkey: () => import( '~/components/layout/surveymonkey' )
  },
  props: {
    title: {
      type: String,
      default: 'Tarvitsetko lainaa?'
    },
    surveyTitle: {
      type: String,
      default: undefined
    },
    boxes: {
      type: Array,
      default: () => ( [
        {
          icon: () => import('~/assets/img/icons/banks.svg?inline'),
          title: 'Yhteistyössä<br>jopa 25<br>luotonantajaa'
        },
        {
          icon: () => import( '~/assets/img/icons/time.svg?inline' ),
          title: 'Nopeasti ja',
          subtitle: 'ilmaiseksi'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Lainaa jopa',
          subtitle: '60 000 €'
        }
      ] )
    }
  }
}
</script>

<style lang="scss">
  .top-points {
    @include until(1310) {
      font-size: 0.7rem;
    }
    &-section {
      border-bottom: 1px solid $grey-lighter;
      line-height: 1;
      padding: 1.5625rem 0 1.875rem 1.875rem;
      &:first-child {
        padding: 0.875rem 0 1.875rem 1.875rem;
      }
      @include until(1310) {
        padding: 1.5625rem 0 1.5625rem 0;
        &:first-child {
          padding: 0.875rem 0 1.875rem 0;
        }
      }
      &-content {
        display: flex;
        h1 {
          max-width: 100%; /** IE Fix **/
        }
      }
      &-block{
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
          padding-left: 1.5625rem;
        }
      }
    }
    &-icon {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      width: 3em;
      svg {
        display: block;
        margin: auto;
        height: 3em;
        fill: $rl-green;
        color: $rl-green;
      }
    }
    .title,
    .subtitle {
      color: $white;
      flex-grow: 1;
    }
  }
</style>
